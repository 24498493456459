<template>
  <div>
    <entity-section :entity="entity" :pending="pending" />
    <agreements-section
      :agreements="agreements"
      @setAgreementsPage="getAgreements"
    />
  </div>
</template>
<script>
import { talentClient } from "@/api";
import EntitySection from "@/components/organizations/EntitySection.vue";
import AgreementsSection from "@/components/organizations/AgreementsSection.vue";
import { initialListingModel } from "@/utils";

export default {
  name: "OrganizationEntityPage",
  components: { EntitySection, AgreementsSection },
  data() {
    return {
      entity: null,
      pending: false,
      agreements: initialListingModel(5),
    };
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization;
    },
  },
  watch: {
    organization: {
      handler(val) {
        if (val?.legal_entity) {
          this.getLegalEntity();
          this.getAgreements(this.agreements.page);
        }
      },
    },
  },
  activated() {
    if (!this.organization?.legal_entity) return;
    this.getLegalEntity();
    this.getAgreements(this.agreements.page);
  },
  methods: {
    async getLegalEntity() {
      const adminId = this.organization?.admin_users?.[0]?.user_id;
      if (!adminId) return;
      this.pending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${adminId}/organizations/${this.organization.id}/legal_entity/`,
        });
        this.entity = data;
      } catch {
        // do nothing
      }
      this.pending = false;
    },
    async getAgreements(page = 1) {
      const adminId = this.organization?.admin_users?.[0]?.user_id;
      if (this.agreements.pending || !adminId) return;
      this.agreements.error = "";
      this.agreements.pending = true;
      this.agreements.page = page;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${adminId}/organizations/${this.organization.id}/legal_entity_agreement/`,
          params: {
            limit: this.agreements.limit,
            offset: (this.agreements.page - 1) * this.agreements.limit,
          },
        });
        this.agreements.list = data.results;
        this.agreements.total = data.count;
        this.agreements.pagesCount = Math.ceil(
          data.count / this.agreements.limit
        );
      } catch (error) {
        console.log("error", error);
        this.agreements.error = error.message;
      }
      this.agreements.pending = false;
    },
  },
};
</script>
