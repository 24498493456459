<template>
  <v-card class="mb-4" :loading="pending || !organization?.id">
    <v-toolbar color="blue-grey darken-2" dark dense flat>
      <v-toolbar-title class="white--text"
        ><v-icon left>mdi-text-box-search-outline</v-icon>Юридическое лицо
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text class="black--text text-body-1 pa-4">
      <p v-if="organization && !organization.legal_entity">
        С&nbsp;данной организацией не&nbsp;ассоциировано не&nbsp;одно
        юридическое лицо
      </p>
      <template v-if="entity">
        <p class="text-h6 flex-grow-1">{{ entity.name }}</p>
        <v-chip
          v-if="moderationStatuses"
          :color="moderationStatuses.color"
          small
          outlined
          class="mr-1 mb-1"
          >{{ moderationStatuses.text }}</v-chip
        >
        <option-row title="ID Юр. Лица:"
          ><talent-link
            :path="`admin/package/legalentity/${entity.id}/change/`"
          >
            {{ entity.id }}
          </talent-link></option-row
        >
        <option-row v-if="entity.type" title="Тип Юр. Лица:">{{
          ENTITY_TYPES[entity.type]
        }}</option-row>
        <option-row v-if="entity.kpp" title="КПП:">{{ entity.kpp }}</option-row>
        <option-row v-if="entity.ogrn" title="ОГРН:">{{
          entity.ogrn
        }}</option-row>
        <option-row v-if="entity.short_name" title="Сокращенное название:">{{
          entity.short_name
        }}</option-row>
        <option-row v-if="entity.legal_address" title="Юридический адрес:">{{
          entity.legal_address
        }}</option-row>
        <option-row v-if="entity.fact_address" title="Фактический адрес:">{{
          entity.fact_address
        }}</option-row>
        <option-row v-if="entity.director" title="Руководитель:">{{
          entity.director
        }}</option-row>

        <option-row v-if="signerFullName" title="ФИО Подписанта:">{{
          signerFullName
        }}</option-row>
        <option-row v-if="entity.signer_phone" title="Телефон подписанта:">{{
          entity.signer_phone
        }}</option-row>
        <option-row v-if="entity.signer_email" title="Почта подписанта:"
          ><a :href="`mailto:${entity.signer_email}`">{{
            entity.signer_email
          }}</a></option-row
        >
        <option-row v-if="entity.signer_post" title="Должность подписанта:">{{
          entity.signer_post
        }}</option-row>
        <option-row v-if="entity.document_title" title="Название документа:">{{
          entity.document_title
        }}</option-row>
        <option-row v-if="entity.document_type" title="Тип документа:">{{
          ENTITY_DOCUMENT_TYPES[entity.document_type]
        }}</option-row>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import TalentLink from "../TalentLink.vue";
const ENTITY_TYPES = {
  legal: "организация",
  physical: "ИП",
};
const ENTITY_DOCUMENT_TYPES = {
  c: "устав организации",
  a: "доверенность",
};
export default {
  name: "EntitySection",
  components: {
    TalentLink,
  },
  props: {
    entity: [Object, null],
    pending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization;
    },
    ENTITY_TYPES() {
      return ENTITY_TYPES;
    },
    ENTITY_DOCUMENT_TYPES() {
      return ENTITY_DOCUMENT_TYPES;
    },
    moderationStatuses() {
      if (!this.entity) return;
      const { status } = this.entity;
      switch (status) {
        case "m":
          return {
            text: "На модерации",
            color: "info",
          };
        case "a":
          return {
            text: "Подтверждено",
            color: "green",
          };
        case "r":
          return {
            text: "Отклонено",
            color: "error",
          };
        default:
          return {
            text: "Не модерировалось",
            color: "grey",
          };
      }
    },
    signerFullName() {
      if (!this.entity) return;
      const { entity } = this;
      return [
        entity.signer_last_name,
        entity.signer_first_name,
        entity.signer_middle_name,
      ]
        .filter(Boolean)
        .join(" ");
    },
  },
};
</script>
