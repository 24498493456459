<template>
  <div>
    <info-section
      class="mb-4"
      :total="agreements.total"
      :pending="agreements.pending"
      icon="mdi-file-edit"
      title="Юридические соглашения"
      :pages="agreements.pagesCount"
      :current-page="agreements.page"
      @setPage="setPage"
    >
      <agreement-card
        v-for="agreement in agreements.list"
        :key="agreement.id"
        :agreement="agreement"
        class="item"
      />
      <div
        v-if="!agreements.list.length && !agreements.pending && isOrganization"
      >
        Нет данных
      </div>
    </info-section>
  </div>
</template>
<script>
import InfoSection from "@/components/InfoSection.vue";
import AgreementCard from "./AgreementCard.vue";
export default {
  name: "AgreementsSection",
  components: { InfoSection, AgreementCard },
  props: {
    agreements: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isOrganization() {
      return !!this.$store.state.organizations.organization;
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setAgreementsPage", page);
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
