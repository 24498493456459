<template>
  <div>
    <option-row v-if="agreement.number" title="Номер:">{{
      agreement.number
    }}</option-row>
    <option-row title="ID соглашения:">{{ agreement.id }}</option-row>
    <option-row v-if="createDate" title="Дата создания:">{{
      createDate
    }}</option-row>
    <option-row v-if="signedOfDate" title="Дата подписания:">{{
      signedOfDate
    }}</option-row>
    <option-row v-if="endDate" title="Дата окончания:">{{
      endDate
    }}</option-row>
    <option-row v-if="file?.datafile" title="Файл соглашения:">
      <a
        :href="file.datafile"
        :download="file.datafile"
        target="_blank"
        class="link"
        rel="noopener noreferrer"
      >
        <span>{{ file.name || file.id }}</span>
      </a>
    </option-row>
  </div>
</template>
<script>
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from "@/constants";
import { talentClient } from "@/api";
import dayjs from "@/plugins/dayjs";
export default {
  name: "AgreementCard",
  props: {
    agreement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    createDate() {
      if (!this.agreement?.created_at) return;
      return dayjs(this.agreement.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    signedOfDate() {
      if (!this.agreement?.date_of_signed) return;
      return dayjs(this.agreement.date_of_signed, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    endDate() {
      if (!this.agreement?.date_of_end) return;
      return dayjs(this.agreement.date_of_end, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
  },
  created() {
    this.getFile();
  },
  methods: {
    async getFile() {
      if (!this.agreement.file) return;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `files/${this.agreement.file}/`,
        });
        if (data.id) {
          this.file = data;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
